import React, { useState, useEffect, Fragment } from "react"

import axios from "axios"

import "../../static/loader.css"
import "../../static/galerija.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Lightbox from "react-awesome-lightbox"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
// You need to import the CSS only once
// import "react-awesome-lightbox/build/style.css"

const Gallery = () => {
  const [main, setMain] = useState(false)
  const [images, setImages] = useState()
  const [dishArray, setDishArray] = useState()
  const [filteredImages, setFilteredImages] = useState()
  const [active, setActive] = useState()
  const [photo, setPhoto] = useState({
    photoIndex: 0,
    isOpen: false,
  })
  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    speed: 1000,

    slidesToShow: 5,
    slidesToScroll: 1,
    useCSS: true,
    useTransform: true,
    autoplaySpeed: 5000,
    autoplay: false,
    pauseOnHover: true,
    lazyLoad: true,
    dotsclassName: "slider-dots",
    className: "slider-galerija",

    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }]
  })

  useEffect(() => {
    axios
      .get(` https://wp.picerija-jaka.si/wp-json/wp/v2/media/?per_page=100`)
      .then(res => {
        let newArray = res.data
        console.log("new array", newArray)
        let imageArray = []
        newArray.map(image => {
          imageArray.push({
            url: image.guid.rendered,

            title: image.acf.kategorija,
            acf: image.acf.kategorija,
          })
        })
        setImages(imageArray)
        const filterImages = imageArray.filter(image => image.acf === "gotove jedi")
        setFilteredImages(filterImages)
        setActive("gotove jedi")
      })
  }, [])

  const setDish = dish => {
    setDishArray(1)

    setTimeout(() => {
      const filtering = images.filter(image => image.acf === dish)
      setFilteredImages(filtering)
      setDishArray(2)
      setActive(dish)
    }, 200)
  }

  return (
    <Layout>
      <SEO title="Galerija" description="Oglejte si fotografije naših jedi in ambienta, kjer lahko praznujete obletnico, se podružite in zabavate." />

      <section className="menus menus--dark picerija-galerija section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 mb-40 text-center">
              <h6 className="sub-title">Picerija jaka</h6>
              <h4 className="title">Galerija</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="row">
                <div className="tabs-icon mb-40 col-lg-10 offset-lg-1 text-center">
                  <Slider {...settings}>
                    <div>
                      <div className={ `item ${active == "gotove jedi" ? "active" : ''}`} onClick={() => setDish("gotove jedi")}>
                        <div className={ "icon food-icon food-icon-Pice "}></div>
                        <h6>Gotove jedi</h6>
                      </div>
                    </div>

                    <div>
                      <div className={ `item ${active == "ambient" ? "active" : ''}`} onClick={() => setDish("ambient")}>
                        <div className={ "icon "}>
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                        </div>
                        <h6>Ambient</h6>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>

              <div className="galerija-container">
                {" "}
                {filteredImages === undefined ? (
                  <div className="loader"></div>
                ) : (
                  filteredImages.map((image, index) => {
                    return (
                      <img
                        src={image.url}
                        key={ index }
                        className={
                          dishArray === 1
                            ? "picerija-hide"
                            : dishArray === 2
                            ? "picerija-show"
                            : ''
                        }
                        onClick={() => setPhoto({ isOpen: true, photoIndex: index })}
                      />
                    )
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {photo.isOpen ? (
        <Lightbox
          images={filteredImages}
          startIndex={photo.photoIndex}
          title="Image Title"
          onClose={() => setPhoto({ isOpen: false })}
        />
      ) : (
        false
      )}
    </Layout>
  )
}

export default Gallery
